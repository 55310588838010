import React from "react"

import Layout from "../components/Layout"
import Head from "../components/Head"

const ContactPage = () => {
  return (
    <Layout>
      <div
        style={{
          background: "white",
          padding: "2rem 1rem",
          textAlign: "center",
          minHeight: "75vh"
        }}
      >
        <Head title="Contact" />
        <h1 style={{ marginTop: "10%" }}>Contact</h1>
        <h2>Email</h2>
        <p>
          The best way to reach us is via{" "}
          <a
            href="https://www.ethiotelecom.et/contact-us/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Our Contact Page
          </a>
        </p>
      </div>
    </Layout>
  )
}

export default ContactPage
